import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/coqui-ai.github.io/coqui-ai.github.io/src/templates/BlogTemplate.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query($fileAbsolutePath: String) {
    ...SidebarPageFragment
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Brace yourself, game developers and tech enthusiasts! Today, we’re about to delve into an
awe-inspiring dimension of game audio design that’s sure to rock your world. Our mission?
To inject an unparalleled level of expressivity and realism into your beloved game
characters. The key to this thrilling evolution? XTTS, our cutting-edge Generative Voice
AI.`}</p>
    <h3 {...{
      "id": "embarking-on-a-new-journey",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#embarking-on-a-new-journey",
        "aria-label": "embarking on a new journey permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Embarking on a New Journey`}</h3>
    <p>{`We’re not just stepping, but leaping ahead with XTTS. This state-of-the-art technology,
fueled by the revolutionary advancements in Generative Voice AI, is all set to redefine
the way you perceive game audio. Think stunningly expressive outputs, superior voice
quality, and a new gold standard for Generative Voice AI game characters. The result?
Not just realistic, but phenomenally lifelike characters that immerse players in
breathtakingly tangible game worlds.`}</p>
    <div align="center">
      <Link to="https://app.coqui.ai/auth/signup" mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "137px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "34.306569343065696%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMAGE",
              "title": "IMAGE",
              "src": "/static/0addc085d063dac762fd9b75a388f199/3f2c9/blog-dive_deeper_into_the_world_of_xtts-try-now-for-free.png",
              "srcSet": ["/static/0addc085d063dac762fd9b75a388f199/3f2c9/blog-dive_deeper_into_the_world_of_xtts-try-now-for-free.png 137w"],
              "sizes": "(max-width: 137px) 100vw, 137px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </Link>
    </div>
    <h3 {...{
      "id": "unleashing-creativity-with-coqui-studio",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#unleashing-creativity-with-coqui-studio",
        "aria-label": "unleashing creativity with coqui studio permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Unleashing Creativity with Coqui Studio`}</h3>
    <p>{`XTTS isn’t just a tech upgrade. It’s a creative powerhouse designed to shatter the
confines of your imagination. At the heart of this is the enhanced Coqui Studio,
your trusty sidekick on this exciting journey.`}</p>
    <p>{`Imagine having prompt-to-voice functionality at your fingertips. One moment, you’re
crafting a dialogue line for a wise-cracking wizard or a brooding cyborg, the next,
you’re hearing them speak in vibrant, lifelike voices that send shivers down your
spine. It’s as simple as inputting your text, describing a voice, and letting
XTTS work its magic.`}</p>
    <p>{`Yet, the features don’t stop there. XTTS also flaunts a revolutionary voice cloning
capability. Recreate the unique voice of any character, irrespective of their
complexity. From the menacing growl of a dragon to the mellifluous tones of a siren,
you can breathe life into them, adding unprecedented depth and character to your
narratives.`}</p>
    <h3 {...{
      "id": "crafting-captivating-audio-narratives",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#crafting-captivating-audio-narratives",
        "aria-label": "crafting captivating audio narratives permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Crafting Captivating Audio Narratives`}</h3>
    <p>{`But why stop at character voices? XTTS empowers you to weave enchanting audio
narratives. You can create not just individual voices, but entire conversational
dynamics, complete with nuanced emotional undertones and idiosyncratic expressions.
The result? A truly immersive gaming experience that leaves players hanging on to
every word, every sound, every beat of their adventure.`}</p>
    <h3 {...{
      "id": "the-invitation-to-experiment-and-explore",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#the-invitation-to-experiment-and-explore",
        "aria-label": "the invitation to experiment and explore permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`The Invitation to Experiment and Explore`}</h3>
    <p>{`Ready to push the boundaries of your game development prowess? XTTS welcomes you
with open arms. Whether you’re perfecting the gravelly voice of a battle-hardened
space marine or refining the accent of an enigmatic elf, XTTS allows you to
experiment, iterate, and customize until you hit the sweet spot.`}</p>
    <h3 {...{
      "id": "were-listening-always",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#were-listening-always",
        "aria-label": "were listening always permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`We’re Listening, Always`}</h3>
    <p>{`But our journey with XTTS isn’t a monologue; it’s a conversation. As you explore the
possibilities, we’re eager to hear your feedback, answer your questions, and
constantly enhance your experience. Remember, each comment, each query, each idea
propels us forward on our shared mission: to take game audio to unparalleled heights.`}</p>
    <p>{`To sum up, XTTS isn’t just a Generative Voice AI; it’s a groundbreaking tool designed
to transform your games into living, breathing universes. So why wait? Step into the
future of game audio design today, and let your characters come alive like never
before!`}</p>
    <div align="center">
      <Link to="https://app.coqui.ai/auth/signup" mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "137px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "34.306569343065696%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMAGE",
              "title": "IMAGE",
              "src": "/static/0addc085d063dac762fd9b75a388f199/3f2c9/blog-dive_deeper_into_the_world_of_xtts-try-now-for-free.png",
              "srcSet": ["/static/0addc085d063dac762fd9b75a388f199/3f2c9/blog-dive_deeper_into_the_world_of_xtts-try-now-for-free.png 137w"],
              "sizes": "(max-width: 137px) 100vw, 137px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </Link>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      